<template>
  <div class="animated fadeIn">
    <b-card :title="$t('message.ratingUse')">
      <Tabs value="washing" @on-click="changeTab">
        <!--            new tab             -->
        <TabPane name="washing">
          <b-form>
            <div class="row">
              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
                {{ $t("message.year") }} :
                <br />
                <b-form-select v-model="timeSel" :options="language== 'th'? timeOptTH: timeOptEN" v-on:change="selectTime" />
              </div>

              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
                {{ $t("message.machineIdFarm") }} :
                <br />
                <multi-list-select :list="machineData" option-value="machineId" option-text="name"
                  :selected-items="selectedMachine" :placeholder="$t('message.all')" @select="onSelectMachine">
                </multi-list-select>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
                {{ $t("message.selectDateTime") }} :
                <br />
                <date-time-picker v-model="searchData.datetime"></date-time-picker>
              </div>

              <!-- <div class="col-sm-6 col-md-6 col-lg-6 col-xl-4 my-1">
                ประเภททำรายการ :
                <br />
                <multi-list-select :list="$t('message.arrays.caseProductType')" option-value="value" option-text="text"
                  :selected-items="selectedProductType" :placeholder="$t('message.all')" @select="onSelectProductType">
                </multi-list-select>
              </div> -->

              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
                {{ $t("message.paymentType") }}:
                <br />
                <multi-list-select :list="$t('message.arrays.casePayment')" option-value="value" option-text="text"
                  :selected-items="selectedPayment" :placeholder="$t('message.all')" @select="onSelectPayment">
                </multi-list-select>
              </div>

              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1">
                {{ $t("message.desiredRank") }} :
                <br />
                <b-form-input v-model="searchData.rank" />
              </div>

              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-3">
                <b-btn class="col-sm-12 col-md-6 my-1" variant="primary" v-on:click="searchFn({ excel: false })">
                  <i class="fa fa-search"></i>
                  &nbsp;{{ $t("message.search") }}
                </b-btn>

                <b-btn class="col-sm-12 col-md-6 my-2 " v-on:click="searchFn({ excel: true })" variant="success"
                  v-if="$isRole('owner', role_id)">
                  <i class="icon-docs"></i>
                  &nbsp;{{ $t("message.export") }}
                </b-btn>
              </div>
            </div>
            <br />
          </b-form>

          <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1"> {{ $t("message.amountCustomer") }} : {{ totalRows | formatNumber }}</div>
            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1"> {{ $t('message.totalRevenue') }} : {{ summary.all |
                formatNumber
            }}</div>
          </div>
          <br />

          <div class="table-responsive">
            <Table stripe row-key="transactionId" size="small" :columns="column" :data="rowData.rows" />
          </div>
          <br />
          <Page :total="totalRows" :page-size="itemperPage" @on-change="changePage" :current="currentPage" />

          <!-- <Page :total="1000" :page-size="100" @on-change="changePage" :current="5" /> -->
        </TabPane>

      </Tabs>
    </b-card>
  </div>
</template>
<script>
import Vue from 'vue'
import webServices from '../../script'
// import DateTimePicker from '../../components/DateTimePicker/Custom.vue'
// import DateTimePicker from "../../components/DateTimePicker/DatePicker.vue";
import DateTimePicker from '../../components/DateTimePicker/Custom.vue'

import FileSaver from 'file-saver'
import { MultiSelect, MultiListSelect } from '../../custom_modules/search-select'
import moment from 'moment'
import VueJsonPretty from 'vue-json-pretty'

//https://stackoverflow.com/questions/44538110/how-to-format-numbers-in-vuejs
var numeral = require('numeral')

Vue.filter('formatNumber', function (value) {
  return numeral(value).format('0,0') // displaying other groupings/separators is possible, look at the docs
})

export default {
  name: 'orders',
  components: {
    // DatePicker,
    DateTimePicker,
    MultiSelect,
    MultiListSelect,
    VueJsonPretty
  },
  data() {
    return {
      api: {},
      timeSel: 0,
      timeOptTH: [
        { value: 0, text: '2022 ถึง ปีปัจจุบัน' },
        { value: 1, text: '<2022'}
      ],
      timeOptEN: [
        { value: 0, text: '2022 To This Year' },
        { value: 1, text: '<2022'}
      ],
      language: window.localStorage.getItem("language"),
      role_id: window.localStorage.getItem('roleID'),
      userBusiness: window.localStorage.getItem('business'),
      rowData: {},
      totalRows: 0,
      totalSubRows: 0,
      currentPage: 1,
      itemperPage: 50,
      selectedProduct: [],
      selectedMachine: [],
      selectedPayment: [],
      selectedProductType: [],
      searchData: {
        rank: 15,
        datetime: [moment().startOf('day').toDate(), moment().endOf('day').toDate()],
        machineId: '',
        paymentType: '',
        type: 'washing',
        productType: '',
      },
      branch: '',
      fromdate: '',
      todate: '',
      startDate: '',
      endDate: '',
      ProductItems: [],
      selectedProduct: [],
      title: [],
      summary: {},
      machineData: [],
      selectedMachine: [],
      sum: {
        changer: {
          coin: {},
          bill: {}
        },
        acceptor: {
          coin: {},
          bill: {}
        }
      },
      column: [
        {
          title: this.$t('message.No'),
          key: 'index',
          minWidth: 50,
          align: 'center'
        },
        {
          title: this.$t('message.machineIdFarm'),
          key: 'machineId',
          minWidth: 60,
          align: 'center'
        },
        {
          title: this.$t('message.refNumber'),
          key: 'refNumber',
          minWidth: 130,
          align: 'right'
        },
        {
          title: this.$t('message.datetime_createdAt'),
          minWidth: 106,
          align: 'center',
          render: (h, params) => {
            return h('span', moment(params.row.CustomerCredits.createdAt).format('YYYY-MM-DD HH:mm'))
          }
        },
        {
          title: this.$t('message.datetime_startAt'),
          minWidth: 106,
          align: 'center',
          render: (h, params) => {
            return h('span', moment(params.row.startDate).format('YYYY-MM-DD HH:mm'))
          }
        },
        {
          title: this.$t('message.datetime_lastAt'),
          minWidth: 106,
          align: 'center',
          render: (h, params) => {
            return h('span', moment(params.row.lastDate).format('YYYY-MM-DD HH:mm'))
          }
        },
        {
          title: this.$t('message.transactions'),
          key: 'trans',
          minWidth: 80,
          align: 'right',
          render: (h, params) => {
            return h('span', Vue.filter('formatNumber')(params.row.trans))
          }
        },
        {
          title: this.$t('message.insert'),
          key: 'total',
          minWidth: 80,
          align: 'right',
          render: (h, params) => {
            return h('span', Vue.filter('formatNumber')(params.row.total))
          }
        },
        {
          title: this.$t('message.detail'),
          // slot: 'action',
          width: 80,
          align: 'center',
          render: this.renderDetail
        }
      ],

    }
  },
  async beforeMount() {
    this.setParams(this.$route.query)
    await this.getMachine()
    this.selectedProduct = this.ProductItems.filter(data => data.id == this.searchData.productId)
    this.selectedMachine = this.machineData.filter(data => data.machineId === this.searchData.machineId)
    if (!this.$isRole('admin', this.role_id) && !this.$isRole('callcenter', this.role_id)) {
      await this.getRatingUse()
    }

    if (this.$route.query.machineId) {
      console.log('data ', this.$route.query)
      this.searchFn()
    }
  },
  methods: {
    selectTime(value) {
      if (value > 0) {
        this.api.defaults.baseURL = '/old/' + this.axios.defaults.baseURL
      } else {
        this.api.defaults.baseURL = this.axios.defaults.baseURL
      }
    },

    setParams(query) {
      if (Object.keys(query).length != 0) {
        // console.log(query)
        this.fromdate = query.from
        this.todate = query.to
        this.searchData.datetime = [moment(query.from).toDate(), moment(query.to).toDate()]
        this.searchData.productId = query.productId || ''
        this.searchData.machineId = query.machineId || ''
      }
    },
    rowClassName() {
      return 'demo-table-info-row'
    },

    renderDetail(h, params) {
      return h('Button', {
        props: {
          type: 'primary',
          size: 'small',
          icon: 'ios-expand'
        },
        style: {
          marginRight: '5px'
        },
        on: {
          click: () => {
            this.detail(params.row)
          }
        }
      })
    },

    async getMachine() {
      this.$Progress.start()
      await this.axios
        .get(`/machines/list`)
        .then(res => {
          this.$Progress.finish()
          this.machineData = res.data
          if (this.machineData.length == 1) {
            this.selectedMachine = this.machineData
          }
        })
        .catch(err => {
          this.$Progress.fail()
          this.$toast.error({
            title: 'ERROR',
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText
            }
          })
          console.log('error @machine')
          console.log(err)
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    },
    isAdmin(role) {
      return webServices.isAdmin(role)
    },
    isDealer(role) {
      return webServices.isDealer(role)
    },
    changePage(PageNum) {
      // window.scrollTo(0, 0)
      this.getRatingUse(PageNum)
    },

    showAlert_ChooseOneBranch() {
      if (window.localStorage.getItem("language") == 'th') {
        var title = "โปรดเลือกช่วงเวลาใหม่อีกครั้ง"
        var text = 'ไม่สามารถเลือกเดือนได้มากกว่า 12 เดือน'
        var confirmButton = "ปิด"
      } else {
        var title = "Please select a new time again"
        var text = "Cannot select more than 12 months."
        var confirmButton = "Close"
      }

      this.$swal({
        title: title,
        text: text,
        icon: 'warning',
        // showCancelButton: true,
        confirmButtonColor: '#3085d6',
        // cancelButtonColor: '#d33',
        confirmButtonText: confirmButton
      })
    },

    showAlert_ChooseThanOneBranch() {
      if (window.localStorage.getItem("language") == 'th') {
        var title = "โปรดเลือกช่วงเวลาใหม่อีกครั้ง"
        var text = "เลือกมากกว่า 1 สาขา \n " + "ไม่สามารถเลือกเดือนได้มากกว่า 2 เดือน\n"
        var confirmButton = "ปิด"
      } else {
        var title = "Please select a new time again"
        var text = "Choose than 1 branch, \n " + "Cannot select more than 2 months.\n"
        var confirmButton = "Close"
      }

      this.$swal({
        title: title,
        html: '<pre>' + text + '</pre>',
        // text: text ,
        icon: 'warning',
        // showCancelButton: true,
        confirmButtonColor: '#3085d6',
        // cancelButtonColor: '#d33',
        confirmButtonText: confirmButton
      })
    },

    monthDiff(d1, d2) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    },

    searchFn(opt = { excel: false }) {
      if (this.searchData.datetime !== '') {
        this.fromdate = moment(this.searchData.datetime[0]).format('YYYY-MM-DD HH:mm:ss')
        this.todate = moment(this.searchData.datetime[1]).format('YYYY-MM-DD HH:mm:ss')
        // this.todate = moment(this.searchData.datetime[1]).endOf('date').format("YYYY-MM-DD HH:mm:ss");
      } else {
        this.fromdate = ''
        this.todate = ''
      }

      if (this.searchData.type != 'washing') {
        this.transactionType = 'ALL'
      }

      var localStorageUser = JSON.parse(window.localStorage.getItem("users"))
      var datetimeRequest = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

      this.searchData.userName = localStorageUser.userName
      this.searchData.userRole = localStorageUser.role
      this.searchData.userType = localStorageUser.type
      this.searchData.userConfiglanguage = localStorageUser.config.language
      this.searchData.datetimeRequest = datetimeRequest

      var dateStart = new Date(this.searchData.datetime[0])
      var dateEnd = new Date(this.searchData.datetime[1])

      // case เลือก 1 สาขา จะเลือกช่วงเวลาได้มากสุด 1 ปี
      if (this.searchData.machineId.length == 1 || window.localStorage.getItem("roleID") == 'dealer') {
        console.log("เลือก 1 สาขา หรือ dealer")
        if (this.monthDiff(dateStart, dateEnd) < 12) {
          if (!opt.excel) {
            this.getRatingUse(1)
          } else {
            this.exportExcel()
          }

        } else {
          console.log("เลือกเดือนได้สูงสุด 12 เดือน")
          this.showAlert_ChooseOneBranch() // เลือกเดือนได้สูงสุด 12 เดือน
        }
        // case เลือกมากกว่า 1 สาขา จะเลือกช่วงเวลาได้มากสุด 2 เดือน
      } else {
        console.log("เลือกมากกว่า 1 สาขา")
        if (this.monthDiff(dateStart, dateEnd) < 2) {
          this.getRatingUse(1)
          if (!opt.excel) {
            this.getRatingUse(1)
          } else {
            this.exportExcel()
          }

        } else {
          console.log("เลือกเดือนได้สูงสุด 2 เดือน")
          this.showAlert_ChooseThanOneBranch() // เลือกเดือนได้สูงสุด 2 เดือน
        }
      }

    },

    detail(data) {
      console.log('log push page order',data)
      const params = {
        // ...this.searchData,
        ...data,
        from: moment(this.searchData.datetime[0]).format('YYYY-MM-DD HH:mm:ss'),
        to: moment(this.searchData.datetime[1]).format('YYYY-MM-DD HH:mm:ss'),
        select_machine: 1,
        byPage: 'analyzeRatingUse'
        // productId: productId,
        // from: moment(this.filters.date[0]).format('YYYY-MM-DD HH:mm:ss'),
        // to: moment(this.filters.date[1]).format('YYYY-MM-DD HH:mm:ss')
      }

      // if (this.filters.machineId) {
      //   params.machineId = this.filters.machineId
      // }

      this.$router.push({ path: '/orders', query: params })
    },

    onSelectMachine(items) {
      // console.log(items)
      this.selectedMachine = items
      this.searchData.machineId = this.selectedMachine.map(data => data.machineId)
      this.searchData.name = this.selectedMachine.map((data) => data.name.substr(9).replaceAll('\\r\\n', ''))
    },

    onSelect(items) {
      // console.log(items)
      this.selectedProduct = items
      this.searchData.productId = this.selectedProduct.map(data => data.id)
    },

    async getRatingUse(page = 1) {
      this.$Progress.start()

      this.currentPage = page

      const rows = this.itemperPage

      const params = {
        ...this.searchData,
        page: page,
        rows: rows,
        machine: this.selectedMachine,
        from: this.searchData.from = moment(this.searchData.datetime[0]).format("YYYY-MM-DD HH:mm:ss"),
        to: this.searchData.to = moment(this.searchData.datetime[1]).format("YYYY-MM-DD HH:mm:ss"),
        // type: "washing",
        // notThinkStatus: [
        //   "CANCEL_BY_MC_FAIL",
        //   "CANCEL_PAYMENT",
        //   "CANCEL_TIMEOUT",
        // ],
      };

      delete params.id
      delete params.datetime

      await this.axios
        .get(`/analyze/ratinguse`, {
          params,
        })
        .then((res) => {
          //   this.SaleByType = res.data.data;
          this.rowData = res.data
          console.log('this.rowData', this.rowData)


          if (res.data) {
            this.totalRows = res.data.trans
            this.summary.all = res.data.total

            for (var i = 0; i < this.rowData.rows.length; i++) {
              if (page > 1) {
                this.rowData.rows[i]['index'] = (50 * (page - 1)) + (i + 1)
              } else {
                this.rowData.rows[i]['index'] = i + 1
              }
            }
          }
          this.$Progress.finish()

        })
        .catch((err) => {
          this.$Progress.fail();
          console.log(err);
          this.$toast.error({
            title: "ERROR",
            message: err,
          });
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
        });
    },

    exportExcel(page = 1) {
      this.$Progress.start()

      // for (let i = 0; i < this.machineData.length; i++) {
      //   if( this.searchData.machineId === this.machineData[i].machineId){
      //     const machineName =  this.machineData[i].name
      //     this.searchData.name = machineName.substr(9).replaceAll('\\r\\n', '')
      //   }
      // }

      if(this.searchData.machineId.length > 0){
        for (let i = 0; i < this.machineData.length; i++) {
          if( this.searchData.id === this.machineData[i].machineId){
            console.log('this.searchData', this.searchData)
            const machineName =  this.machineData[i].name
            // this.searchData.machineId = this.machineData[i].machineId
            this.searchData.name = machineName.substr(9).replaceAll('\\r\\n', '')
          }
        }

        this.searchData.branch = this.searchData.machineId
      }else{
        this.searchData.branch = '...'
        this.searchData.name = '...'
      }

      console.log('this.searchData', this.searchData)
      // const dateStart = new Date(this.fromdate)
      // const dateEnd = new Date(this.todate)


      this.currentPage = page

      const rows = this.itemperPage

      const params = {
        ...this.searchData,
        page: page,
        rows: rows,
        machine: this.selectedMachine,
        from: this.searchData.from = moment(this.searchData.datetime[0]).format("YYYY-MM-DD HH:mm:ss"),
        to: this.searchData.to = moment(this.searchData.datetime[1]).format("YYYY-MM-DD HH:mm:ss"),
        // type: "washing",
        // notThinkStatus: [
        //   "CANCEL_BY_MC_FAIL",
        //   "CANCEL_PAYMENT",
        //   "CANCEL_TIMEOUT",
        // ],
      };

      // delete params.id
      // delete params.datetime


      this.axios({
        url: `/analyze/ratinguse/download`, // File URL Goes Here
        method: 'GET',
        params,
        responseType: 'blob'
      }).then(response => {
        if (this.searchData.machineId.length > 0) {
          this.branch = this.searchData.machineId
        } else {
          this.branch = '...'
        }
        FileSaver.saveAs(response.data, `Report_Rating_Use_${this.branch}_date_${moment(this.fromdate).format('YYYY-MM-DD')}_to_${moment(this.todate).format('YYYY-MM-DD')}.xlsx`)
        this.$Progress.finish()
      })
    },

    onSelectMachine(items) {
      // console.log(items)
      this.selectedMachine = items
      this.searchData.machineId = this.selectedMachine.map(data => data.machineId)
      this.searchData.name = this.selectedMachine.map((data) => data.name.substr(9).replaceAll('\\r\\n', '') )
    },


    onSelectProductType(items) {
      // console.log('onSelectType', items)
      this.selectedProductType = items;
      this.searchData.productType = this.selectedProductType.map((data) => data.value);
    },

    onSelectPayment(items) {
      // console.log('onSelectType', items)
      this.selectedPayment = items;
      this.searchData.paymentType = this.selectedPayment.map( (data) => data.value );
    },

    changeTab(params) {
      this.rowData = {}
      this.totalRows = 0
      this.totalSubRows = 0
      this.summary = {}
      this.searchData.type = params
      if (!this.$isRole('admin', this.role_id) && !this.$isRole('callcenter', this.role_id)) {
        this.searchFn()
      }
    }
  }
}
</script>

<style>
@import './../style.css';

.spanCustom {
  cursor: pointer;
  color: #20a8d8;
  border-color: #20a8d8;
  text-decoration: underline;
}

.spanCustom:hover {
  text-decoration: none;
  text-shadow: 1px 1px 1px #555;
}

.ivu-table-cell {
  font-size: 0.9em;
  padding-left: 10px;
  padding-right: 0px;
}

.ivu-table-small td {
  height: 30px;
}

.ivu-tag {
  font-size: 0.9em;
  margin: 0 2px 0 0;
  padding: 0 4px;
}
</style>
